import store from "@/store";

import globalToast from '@/composables/common/globalToast';

const changeRequestsLoader = () => {
    const { showGlobalToast } = globalToast();

    const loadChangeRequests = () => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        const requestObj = {
            type: 'changerequests',
            request: 'loadchangerequests',
            validateusername: username,
            validatepassword: password
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){                
                store.dispatch('dr_changeRequestArry', responseObj.changeRequests)
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { loadChangeRequests }
}

export default changeRequestsLoader;