
import store from '@/store';
import { computed, defineComponent, onMounted, ref } from 'vue';
import './ChangeRequests.scss';

import globalToast from '@/composables/common/globalToast';
import changeRequestsLoader from '@/composables/changeRequests/changeRequestsLoader';
import renderDateFromServer from '@/composables/common/renderDateFromServer';
import doActionOnRequests from '@/composables/changeRequests/doActionOnRequests';
import abadiRouter from '@/composables/datauploadsinfo/abadiRouter';
import flightRouter from '@/composables/datauploadsinfo/flightRouter';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { loadChangeRequests } = changeRequestsLoader();
        const { renderDate } = renderDateFromServer();
        const { approveChangeRequest, discardChangeRequest } = doActionOnRequests();
        const { goToAbadi } = abadiRouter();
        const { goToFlight } = flightRouter();
        
        const changeRequestsMode = ref('pendingchanges');

        const dr_changeRequestArryPending = computed(() => {
            return store.getters.dr_changeRequestArry
            .filter((request: any) => request.requeststatus == 'pending');
        });

        const dr_changeRequestArryDone = computed(() => { 
            return store.getters.dr_changeRequestArry
            .filter((request: any) => request.requeststatus != 'pending').reverse();
        });

        onMounted(() => {
            loadChangeRequests();
        });

        const approveRequest = (e: any) => {
            let uuid = e.target.getAttribute('uuid');
            // console.log(rowNode);

            approveChangeRequest(uuid);
        }

        const discardRequest = (e: any) => {
            let uuid = e.target.getAttribute('uuid');
            // console.log(rowNode);

            discardChangeRequest(uuid);
        }

        const callGoToAbadi = (e: any) => {
            let abadilimituuid = e.target.getAttribute('abadilimituuid');
            // console.log(abadilimituuid);
            goToAbadi(abadilimituuid);
        }

        const callGoToFlight = (e: any) => {
            let flightid = e.target.getAttribute('flightid');
            // console.log(flightid);
            goToFlight(flightid);
        }

        return {
            dr_changeRequestArryPending, dr_changeRequestArryDone,
            changeRequestsMode, renderDate,
            approveRequest, discardRequest,
            callGoToAbadi, callGoToFlight
        }
    },
})
