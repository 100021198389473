import store from "@/store";

import router from "@/router";

import globalToast from '@/composables/common/globalToast';
import flightLinesLoader from '@/composables/geojsons/flightLinesLoader';

const flightRouter = () => {
    const { showGlobalToast } = globalToast();
    const { setFlightLines } = flightLinesLoader();

    const goToFlight = (flightid: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        const requestObj = {
            type: 'uploadsinfo',
            request: 'getflightinfosingle',
            validateusername: username,
            validatepassword: password,
            flightid
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){                
                loadSingleAbadiNZoomToIt(responseObj);
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const loadSingleAbadiNZoomToIt = (responseObj: any) => {
        const { featureCollection } = responseObj;
        setFlightLines(featureCollection);
        
        resizeRouterContainer();
        routerToFlightManager();
        clearPreviousFlightLineInfoFromStore();
    }

    const routerToFlightManager = () => {
        router.push({
            name: 'FLIGHTS_MANAGER'
        });

        const g_routeSelectElRef = store.getters.g_routeSelectElRef;
        g_routeSelectElRef.value = `FLIGHTS_MANAGER`;
    }
    
    const resizeRouterContainer = () => {
        const g_routercontainerEl = store.getters.g_routercontainerEl;
        g_routercontainerEl.style.height = `250px`;
    }

    const clearPreviousFlightLineInfoFromStore = () => {
        store.commit('fi_flightInfo', {});
    }

    return { goToFlight }
}

export default flightRouter;