import store from "@/store";

import router from "@/router";

import globalToast from '@/composables/common/globalToast';
import abadiLimitsLoader from '@/composables/geojsons/abadiLimitsLoader';
import handleClicks from '@/composables/mapEvents/handleClicks';

const abadiRouter = () => {
    const { showGlobalToast } = globalToast();
    const { setAbadiLimits } = abadiLimitsLoader();
    const { loadAbadiInfoFromServer, loadAbadiFilesListFromServer } = handleClicks();

    const goToAbadi = (abadilimituuid: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        const requestObj = {
            type: 'uploadsinfo',
            request: 'getabadiinfosingle',
            validateusername: username,
            validatepassword: password,
            abadilimituuid
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){                
                loadSingleAbadiNZoomToIt(responseObj);
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const loadSingleAbadiNZoomToIt = (responseObj: any) => {
        const { featureCollection } = responseObj;
        setAbadiLimits(featureCollection);

        let abadilimituuid = featureCollection.features[0].properties['abadilimituuid'];
        
        loadDataFromServer(abadilimituuid);
        resizeRouterContainer();
        routerToAbadiFiles();
    }

    const loadDataFromServer = (abadilimituuid: any) => {
        loadAbadiInfoFromServer({ abadilimituuid });
        loadAbadiFilesListFromServer({ abadilimituuid });
    }

    const routerToAbadiFiles = () => {
        router.push({
            name: 'ABADIS_MANAGER'
        });

        const g_routeSelectElRef = store.getters.g_routeSelectElRef;
        g_routeSelectElRef.value = `ABADIS_MANAGER`;
    }
    
    const resizeRouterContainer = () => {
        const g_routercontainerEl = store.getters.g_routercontainerEl;
        g_routercontainerEl.style.height = `250px`;
    }

    return { goToAbadi }
}

export default abadiRouter;