const renderDateFromServer = () => {
    const renderDate = (dateStr: any) => {
        try{
            let newDate = new Date(dateStr);
            // console.log(newDate);
            const offset = newDate.getTimezoneOffset();
            newDate = new Date(newDate.getTime() - (offset * 60 * 1000));
            return newDate.toISOString().split('T')[0];
        } catch (e) {
            return '';
        }
    }

    return { renderDate }
}

export default renderDateFromServer;